
import NavItem from "@/components/NavItem.vue";

export default {
  name: 'NavBar',
  props: {
    items: {
      type: Object//[{name:String,url:String,selected:Boolean}],
    },
  },
  components: {
    NavItem,
  }
}
