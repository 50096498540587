

export class CellData {
  constructor(title: string, description: string[], icon: string, button_text?:string, button_action?:string) {
    this.title = title;
    this.description = description;
    this.icon = icon;
    this.button_text = button_text;
    this.button_action = button_action;
  }
  title: string;
  description: string[];
  icon: string;
  button_text?: string;
  button_action?: string;
}

export default {
  name: "Cell",
  props: {
    celldata: {
      type: CellData,
    },
    image: {
      type: String,
    },
    title: {
      type: String,
    },
    description: {
      type: Array,
    },
    btntxt: {
      type: String,
    },
    btnact: {
      type: String,
    },
  },
}


