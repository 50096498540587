

export default {
  name: 'NavItem',
  props: {
    name: {
      type: String,
    },
    url: {
      type: String,
    },
    selected: {
      type: Boolean,
    },
  },
}


