import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-94ce0d0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col col-6" }
const _hoisted_2 = { class: "row g-0 border rounded bg-black overflow-hidden flex-md-row mb-4 shadow-sm h-md250 position-relative" }
const _hoisted_3 = { class: "col-auto d-none d-lg-block" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "col p-4 d-flex flex-column position-static align-items-left" }
const _hoisted_6 = ["onclick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          class: "cell-icon-img",
          src: require('@/assets/' + ($props.celldata!=undefined?$props.celldata.icon:$props.image)),
          width: "150",
          height: "150",
          role: "img"
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h2", null, _toDisplayString($props.celldata!=undefined?$props.celldata.title:$props.title), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(($props.celldata!=undefined?$props.celldata.description:$props.description), (desc, key) => {
          return (_openBlock(), _createElementBlock("p", {
            key: key,
            class: "mb-0"
          }, _toDisplayString(desc), 1))
        }), 128)),
        ($props.celldata!=undefined?$props.celldata.button_text:$props.btntxt)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn bg-dark text-light",
              onclick: $props.celldata!=undefined?$props.celldata.button_action:$props.btnact
            }, _toDisplayString($props.celldata!=undefined?$props.celldata.button_text:$props.btntxt), 9, _hoisted_6))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}