import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", null, [
    _createElementVNode("a", {
      href: $props.url,
      class: _normalizeClass(`nav-link px-2 text-${($props.selected)?'secondary':'light'}`)
    }, _toDisplayString($props.name), 11, _hoisted_1)
  ]))
}