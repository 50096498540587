
import {Options, Vue} from 'vue-class-component';
import NavBar from '@/components/NavBar.vue';
import Foot from '@/components/Foot.vue';
import Cell, {CellData} from '@/components/Cell.vue';

@Options({
  components: {
    NavBar,
    Foot,
    Cell,
  },
})
export default class App extends Vue {
  navitems = [{name:'Home',url:'/',selected:true},{name:'Programs',url:'/programs.html'}];
  about_cell = new CellData("About Me", ["Current Age: ##AGE##","Education: 2nd Year CIS Major"], "logo.svg")
  maintenance_cell = new CellData("Under Maintenance", ['The site is currently under maintenance.', 'There are major changes happening to make the site better and more modern!', 'The old website used antiquated methods for formatting the page such as tables so I am completely rewriting the code using the VUE.JS framework and Bootstrap!','\xa0'], "logo.svg", "Old Site (May be Broken)", "location.href='/old'")
}

