import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "footer mt-auto py-3 text-center text-light bg-black",
  role: "contentinfo"
}
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, "©" + _toDisplayString($options.year) + " Aiden Sheeran", 1)
    ])
  ]))
}