

export default {
  name: "Foot",
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  computed: {
    year() { 
      return new Date().getFullYear().toString() 
    },
  },
}


